<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoProduct",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoProduct",
        mrProdCat: {},
        category: {},
        statusProduct: {},
        row: {
          category: '',
          statusProduct: ''
        },
        mpTitleLength: 0,
        mrClass: [],
        bg: {},        
        metatitleLength: 0,
        maxmetatitleLength: 60,
        metadescLength: 0,
        maxmetadescLength: 160,
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data.data,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      submitBackground() {
        BOGen.apirest('/' + this.Name, {
          data: this.bg,
          type: 'updateBackground'
        }, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.back-info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.back-info')
            this.refreshData()
            setTimeout(() => {
              $('#collapseExample').hide()
            }, 3500)
          }
        }, "POST");
      },
      addRow() {
        this.row.ap_claim.push({
          title: '',
          class: ''
        });
      },
      sort() {
        this.filter.sort = this.filter.sort == 'all' ? '' : 'all'
        if (this.filter.sort == 'all') {
          this.filter.category = ''
          this.filter.statusProduct = ''
        }
        var query = this.filter
        delete query.page
        this.$router.push({
          name: this.Name,
          query: query
        })
      }
    },
    watch: {
      $route(){
        this.row = {}
      },
      '$route.query'() {
        if (this.$route.params.id) {
          this.$set(this.row, 'category', this)
          this.$set(this.row, 'statusProduct', this)
          this.$set(this.row, 'search', this)
          this.$set(this.row, 'page', this)
          setTimeout(() => {
            this.row.category = this.$route.query.category
            this.row.statusProduct = this.$route.query.statusProduct
            this.row.search = this.$route.query.search
            this.row.page = this.$route.query.page
          }, 500)
        }
        this.refreshData()
      },
      'row.ap_name'(v) {
        if(this.row.type === 'add'){
         this.row.ap_slug = v.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
        }
      },
      'row.ap_slug'(v){
        this.row.ap_slug = v.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
      },
      'filter.category'(v) {
        this.search()
      },
      'filter.statusProduct'(v) {
        this.search()
      },
      'filter.search'(v){
        this.search()
      },      
      'row.ap_seo_title'(v){
         this.metatitleLength = v.length
      },
      'row.ap_seo_desc'(v){
         this.metadescLength = v.length
      }
    }
  };
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <!-- ======================================================================== 
      LIST DATA
    ============================================================================= -->
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">New Product</h5>
          </div>
          <div class="card-body">
            <VForm @resp="submitBackground" method="post">
              <div class="back-info"></div>
              <div class="row">
                <div class="col-md-4">
                  <BoField name="as_val" mandatory :label="'Background'" class="mb-0">
                    <Uploader :param="{thumbnail:true}" name="as_val" v-model="bg.as_val" type="background"
                      uploadType="cropping"></Uploader>
                  </BoField>
                </div>
                <div class="col-md-6">
                  <label for="">Background Preview</label>
                  <div class="bg-preview bg-preview--bg-blue bg-preview--bg-bottom" :style="'background-image:url('+uploader(bg.as_val)+')'"></div>
                </div>
              </div>
              <hr>
              <div class="text-right">
                <button type="submit" v-if="page.moduleRole('Edit')" class="btn btn-rounded btn-info btn-loading">Update Background</button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            <VForm @resp="search">
              <div class="row gutter-10 align-items-center">
                <div class="col-lg-2 col-6">
                  <h5 class="card-title">{{ObjectName}} List</h5>
                </div>
                <div class="col-lg-2 col-6 text-right ml-lg-auto">
                  <button class="btn btn-success" type="button" @click="sort()">
                    <i class="fas fa-sort"></i> Show All
                  </button>
                </div>
                <div class="col-lg-2 col-sm-3 ml-sm-auto ml-lg-0">
                  <select2 :options="category" :object="['apc_id','apc_name']" v-model="filter.category">
                    <option value="">-- Select Category --</option>
                  </select2>
                </div>
                <div class="col-lg-2 col-sm-3">
                  <select2 :options="statusProduct" v-model="filter.statusProduct">
                    <option value="">-- Select Status --</option>
                  </select2>
                </div>
                <div class="col-9 col-lg-3 col-sm-4">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                        placeholder="Search...">
                      <div class="input-group-append">
                        <button class="btn btn-info" type="button" @click="search()">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 col-sm-auto">
                  <router-link :to="{name:Name}" class="btn btn-warning btn-block">Reset</router-link>
                </div>
              </div>
            </VForm>
          </div>

          <div class="card-body">
            <draggable v-model="data.data" tag="div" class="row gutter-20" @end="endDrag">
              <div class="col-lg-3 col-md-4" v-for="(v,k) in data.data" :key="k">
                <div class="item-list collection">
                  <div class="row text-center">
                    <div class="col-md-12">
                      <div class="product-img recipe-cat">
                        <img :src="uploader(v.ap_img,'250')" alt="prod">
                        <div class="pro-img-overlay">
                          <router-link v-if="page.moduleRole('Edit')" class="bg-info" :to="{name:Name,params:{id:v.id},query:$route.query}"
                            v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
                          <a href="javascript:;" class="bg-danger" v-if="page.moduleRole('Delete')" @click="deleteItem($event,k)" v-tooltip="'Delete'"><i
                              class="ti-trash"></i></a>
                        </div>
                        <div class="label bg-transparent p-0">
                          <span class="label label-info mr-1">{{v.apc_name}}</span>
                          <span class="label label-success" v-if="v.ap_is_active=='Y'">Active</span>
                          <span class="label label-danger" v-else>Inactive</span>
                        </div>
                      </div>
                      <h5 class="card-title mt-3">{{v.ap_name}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
            <div v-if="NotFound" class="text-center col-md-12">
              <h5 class="tc">{{NotFound}}</h5>
            </div>
            <div v-if="data.data===false" class="text-center col-md-12">
              <LoadingSpinner light></LoadingSpinner>
            </div>
            <div class="row">
              <div class="col-12">
                <Pagination :data="data" :limit="3" @pagination-change-page="onPaging" class="justify-content-end mt-3 mb-0">
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ======================================================================== 
      FORM CRUD 
    ============================================================================= -->
    <div class="card" v-if="$route.params.id">
      <VForm @resp="submitForm" method="post">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-8">
              <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
            </div>
          </div>
          <div class="info"></div>
          <div class="row">
            <div class="col-sm-8">
              <BoField name="ap_name" v-model="row.ap_name"></BoField>
              <BoField name="ap_slug" v-model="row.ap_slug"></BoField>
              <BoField name="ap_cat_id">
                <select2 name="ap_cat_id" v-bind="validation('ap_cat_id')" :options="mrProdCat"
                  :object="['apc_id','apc_name']" v-model="row.ap_cat_id">
                  <option value="">-- Select Category --</option>
                </select2>
              </BoField>
              <BoField name="ap_desc" mandatory>
                <CKEditor name="ap_desc" v-model="row.ap_desc" v-bind="validation('ap_desc')"></CKEditor>
              </BoField>
              <BoField name="ap_netto" :attr="{placeholder:'500mg'}" v-model="row.ap_netto"></BoField>
              <BoField name="ap_link" :attr="{type:'url',placeholder:'https://www.nutrimart.co.id/'}"
                v-model="row.ap_link"></BoField>
              <!-- =================  -->
              <div class="py-3 px-3 border mb-3">
                <div class="row mb-3">
                  <div class="col-lg-8">
                    <h5 class="card-title mb-2">Claim</h5>
                    <div class="variants" v-for="(vr,k) in row.ap_claim" :key="k">
                      <div class="form-row">
                        <div class="col-9 col-md-8">
                          <BoField class="mb-0" :attr="{placeholder:'Ex: Tinggi Vitamin D'}" v-bind="validation('title')" v-model="vr.title"></BoField>
                          <BoField name="class">
                            <select2 name="class" :attr="{required:'required'}" v-bind="validation('ap_cat_id')" :options="mrClass"
                              v-model="vr.class">
                              <option value="">-- Select Color --</option>
                            </select2>
                          </BoField>
                        </div>
                        <div class="col-3 col-md-4 text-left" style="margin-top: 22px;">
                          <a @click="addRow()" v-if="row.ap_claim.length<5" href="javascript:;" class="btn btn-info mr-1"
                            v-tooltip="'Add Variant'"><i class="ti-plus"></i></a>
                          <a v-if="row.ap_claim.length>1" @click="row.ap_claim.splice(k,1)" href="javascript:;"
                            class="btn btn-danger" v-tooltip="'Delete Variant'"><i class="ti-trash"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <BoField name="ap_is_active">
                <radio name="ap_is_active" v-model="row.ap_is_active" option="Y"
                  :attr="validation('ap_is_active')">Active</radio>
                <radio name="ap_is_active" v-model="row.ap_is_active" option="N">Inactive</radio>
              </BoField>
              <!-- =================  -->
            </div>
            <div class="col-sm-4">
              <BoField name="ap_img">
                <Uploader name="ap_img" type="product" uploadType="cropping" :param="{thumbnail:true}"
                  v-model="row.ap_img"></Uploader>
              </BoField>
              <BoField name="ap_img_gizi" class="mb-0">
                <Uploader name="ap_img_gizi" type="gizi" uploadType="upload" :param="{thumbnail:true}"
                  v-model="row.ap_img_gizi"></Uploader>
              </BoField>
            </div>
            <div class="col-12">
              <hr>
              <h5 class="card-title mb-3">Meta Content</h5>
              <div class="row">
                <div class="col-sm-6">
                  <BoField class="mb-0" name="ap_seo_title" :attr="{minlength:'3'}" v-model="row.ap_seo_title" mandatory></BoField>
                  <small class="mb-2 d-inline-block text-info">{{maxmetatitleLength-metatitleLength}} character remaining </small>
                  <BoField name="ap_seo_keyword" mandatory>
                    <TagsInput name="ap_seo_keyword" v-model="row.ap_seo_keyword" :attr="validation('ap_seo_keyword')" :placeholder="'Add tag then enter'">
                    </TagsInput>
                  </BoField>
                </div>
                <div class="col-sm-6">
                  <BoField class="mb-0" name="ap_seo_desc" mandatory>
                    <textarea name="ap_seo_desc" rows="5" :attr="{minlength:'10'}" v-model="row.ap_seo_desc"
                      v-bind="validation('ap_seo_desc')" class="form-control"></textarea>
                  </BoField>
                  <small class="mb-2 d-inline-block text-info">{{maxmetadescLength-metadescLength}} character remaining </small> 
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="text-right">
                <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded">{{$route.params.id=='add'?'Add Product':' Update Product'}}
                  <i class="icon-arrow-right14 position-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </VForm>
    </div>
  </div>
</template>